import React, {} from "react";
import { StyledProductListContainer, StyledProductList } from "./style";
import ProductCard from "../ProductCard/ProductCard";
import Scroller from "../Scroller/Scroller";
import { Helmet } from "react-helmet";

export default ({
  items,
}) => {

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(items.map(e => {
            const outOfStock = e.quantityLeft[0] == "0";
            return {
              "@context":"https://schema.org",
              "@type": "Product",
              "name": e.name,
              "image": e.image,
              "sku": e.productId,
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": e.description,
              "offers": {
                "@type": "Offer",
                "price": e.finalPrice,
                "priceCurrency": "EUR",
                "availability": outOfStock ? "OutOfStock" : "InStock",
              },
            };
          }))}
        </script>
      </Helmet>

      <StyledProductListContainer id="perks">
        <Scroller>
          <StyledProductList>
            {items.map((item, index) => (
              <ProductCard key={index} {...item} />
            ))}
          </StyledProductList>
        </Scroller>
      </StyledProductListContainer>
    </>
  );
};
